import StyledMain from './components/Main';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import StyledDashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import { YMInitializer } from 'react-yandex-metrika';

function App() {
    const counter = process.env.REACT_APP_YM_COUNTER;

    return (
        <>
        {!!counter && <YMInitializer accounts={[counter]} options={{webvisor: true}}/>}
            <Router>
                <Switch>
                    <PrivateRoute path="/admin" component={StyledDashboard} />
                    <Route exact path="/" component={StyledMain} />
                </Switch>
            </Router>
        </>
    );
}

export default App;
