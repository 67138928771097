import { createContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import StyledFooter from './Footer';
import Menu from './Menu';
import dayjs from 'dayjs';
import recurring from '../images/recurring.svg';
import arrowLeft from '../images/arrow-left.svg';
import arrowRight from '../images/arrow-right.svg';
import AppointmentAdmin from './AppointmentAdmin';
import DataTable from 'react-data-table-component';
import StyledDate from './Date';
import Remove from './Remove';

export const Context = createContext(null);

const Dashboard = ({ className }) => {
    const [appointments, setAppointments] = useState([]);

    useEffect(() => {
        fetchAppointments();
    }, []);

    const fetchAppointments = () => {
        const jwt = JSON.parse(sessionStorage.getItem('jwt'));

        fetch(process.env.REACT_APP_API + '/api/appointments/all', {
            headers: { 'x-access-token': jwt.accessToken },
        })
            .then(res => res.json())
            .then(res => res.reverse())
            .then(res => setAppointments(res))
            .catch(err => console.log(err));
    };

    const typeFancy = type => {
        switch (type) {
            case 'visit':
                return 'Визит';
            case 'site':
                return 'Заявка';
            case 'occupied':
                return 'Занят';
            default:
                return '';
        }
    };

    const [settings, setSettings] = useState({
        dates: [],
        settings: { startHour: 0, endHour: 23, workdays: Array(7).fill(0) },
    });

    const [week, setWeek] = useState(0);

    const [reccurent, setReccurent] = useState(null);

    const setNextWeek = () => {
        if (week >= 4) return;
        setWeek(week + 1);
    };

    const setPreviousWeek = () => {
        if (week <= -2) return;
        setWeek(week - 1);
    };

    useEffect(() => {
        fetch(process.env.REACT_APP_API + '/api/appointments/occupied')
            .then(res => res.json())
            .then(res => setSettings(res))
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        let map = new Map();
        appointments
            .filter(d => d.recurring)
            .forEach(d => {
                const date = dayjs(d.date);
                const key = `${date.day() + 1}_${date.hour()}`;
                if (!map.has(key)) {
                    map.set(key, date);
                }
            });
        setReccurent(map);
    }, [appointments]);

    const monthName = (week, i) => {
        let str = dayjs()
            .add(week * 7 + i + 2, 'day')
            .format('MMM');
        str = str[0].toUpperCase() + str.slice(1);
        return str.replace('.', '');
    };

    function time(week, i, j) {
        const key = i + '_' + j;

        const date = dayjs()
            .add(week * 7 + i + 1, 'day')
            .hour(j)
            .minute(0)
            .second(0)
            .millisecond(0);

        if (appointments.some(d => d.date === date.toISOString())) {
            const appointment = appointments.find(d => d.date === date.toISOString());
            return (
                <>
                    <span className={'disabled date'}>{date.format('HH:mm')}</span>
                    <StyledDate date={date} key={key} appointment={appointment} />
                </>
            );
        }

        if (!!reccurent) {
            const key = `${date.day() + 1}_${date.hour()}`;
            if (reccurent.has(key)) {
                const appointment = appointments.find(d => d.date === reccurent.get(key).toISOString());
                return (
                    <>
                        <span className={'disabled date'}>{date.format('HH:mm')}</span>
                        <StyledDate date={date} key={key} appointment={appointment} reccurent={true} />
                    </>
                );
            }
        }

        return settings.settings.workdays[date.weekday()] === 1 ? (
            <>
                <AppointmentAdmin time={date} />
                <StyledDate date={date} key={key} available={true} />
            </>
        ) : (
            <>
                <span className={'disabled date'}>{date.format('HH:mm')}</span>
                <StyledDate date={date} key={key} />
            </>
        );
    }

    function range(size, startAt = 0) {
        return [...Array(size).keys()].map(i => i + startAt);
    }

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            width: '60px',
        },
        {
            name: 'Тип',
            selector: row => {
                return <span className={'table-row-type ' + row.type}>{typeFancy(row.type)}</span>;
            },
            sortable: true,
            width: '90px',
        },
        {
            name: 'Дата создания',
            selector: row => dayjs(row.createdAt).format('DD.MM.YY HH:mm'),
            sortable: true,
            width: '140px',
        },
        {
            name: 'Дата сеанса',
            selector: row => {
                return row.date == null ? (
                    <span className="muted">не выбрана</span>
                ) : (
                    <>
                        {dayjs(row.date).format('dd, DD.MM.YY HH:mm')}
                        {row.recurring && <img src={recurring} className={'recurring'} width={12} alt="Еженедельный" />}
                    </>
                );
            },
            sortable: true,
            width: '170px',
        },
        {
            name: 'Имя',
            selector: row => {
                return <b>{row.name}</b>;
            },
            sortable: true,
            width: '140px',
        },
        {
            name: 'Контакт',
            selector: row => row.contact,
            sortable: true,
            width: '140px',
        },
        {
            name: 'Сообщение',
            selector: row => row.message,
            sortable: true,
        },
        {
            name: 'Примечание',
            selector: row => row.comment,
            sortable: true,
        },
        {
            width: '70px',
            selector: row => {
                return (
                    <div className="table-row">
                        <AppointmentAdmin edit={true} appointment={row} />
                        <Remove
                            appointment={row}
                            callback={() => {
                                setReccurent(null);
                                fetchAppointments();
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    return (
        <div className={className}>
            <Context.Provider value={fetchAppointments}>
                <Menu />
                <div className="main">
                    <div className={'calendar'}>
                        <div className={'week-picker'}>
                            <img
                                src={arrowLeft}
                                className={week <= -2 ? 'disabled' : 'enabled'}
                                width="20"
                                onClick={setPreviousWeek}
                                alt="Следующая неделя"
                            />
                            <img
                                src={arrowRight}
                                className={week >= 4 ? 'disabled' : 'enabled'}
                                width="20"
                                onClick={setNextWeek}
                                alt="Предыдущая неделя"
                            />
                        </div>
                        <div className={'picker'}>
                            {[...Array(7)].map((x, i) => (
                                <div className={'grid'} key={i}>
                                    <div className={'month'}>
                                        {i === 0 ||
                                        dayjs()
                                            .add(week * 7 + i + 2, 'day')
                                            .month() !==
                                            dayjs()
                                                .add(week * 7 + i + 1, 'day')
                                                .month()
                                            ? monthName(week, i)
                                            : null}
                                    </div>
                                    <div></div>
                                    <div className={'week'}>
                                        {dayjs()
                                            .add(week * 7 + i + 1, 'day')
                                            .format('D dd')}
                                    </div>
                                    <div></div>
                                    {range(
                                        settings.settings.endHour - settings.settings.startHour + 1,
                                        settings.settings.startHour
                                    ).map(j => time(week, i, j))}
                                </div>
                            ))}
                        </div>
                    </div>
                    <DataTable columns={columns} data={appointments} dense highlightOnHover />
                    <StyledFooter />
                </div>
            </Context.Provider>
        </div>
    );
};

const StyledDashboard = styled(Dashboard)`
    * {
        font-family: system-ui;
        font-size: 0.8rem;
    }

    .main {
        padding: 1rem 1rem;
    }

    .grid {
        display: grid;
        grid-template-columns: auto 80px;
        grid-column-gap: 5px;
    }

    .date {
        text-align: right;
    }

    .recurring {
        opacity: 0.3;
        margin-left: 5px;
    }

    button {
        background: none;
        border: none;
    }

    & {
        z-index: 1056;
    }

    img .small {
        margin: 0;
        cursor: initial;
    }

    ul {
        list-style-type: none;
        margin: unset;
        padding: unset;
    }

    .week-picker {
        text-align: right;
        margin-bottom: 0.8rem;
    }

    .picker {
        display: flex;
        justify-content: center;
        gap: 1rem;
    }

    .month {
        min-height: 24px;
        font-weight: bold;
        margin-bottom: 0.4rem;
    }

    .week {
        text-transform: uppercase;
        font-size: 0.8rem;
        white-space: nowrap;
        width: fit-content;
        margin-left: auto;
    }

    .disabled {
        opacity: 0.3;
    }

    button {
        max-width: 300px;
    }

    button:hover {
        color: #ffffff;
    }

    .calendar {
        margin-bottom: 4rem;
    }

    .table-row {
        display: flex;
        align-items: end;
    }

    .table-row .edit {
        width: 12px;
    }

    .table-row-type {
        padding: 5px;
    }

    .occupied {
        background: #eee;
    }

    .site {
        background: #ffdea0;
    }

    .visit {
        background: #d2d2ff;
    }
`;

export default StyledDashboard;
