import styled from 'styled-components';
import photo from '../images/photo.png';
import StyledContacts from './Contacts';
import StyledFooter from './Footer';
import StyledAppointment from './Appointment';
import StyledFaq from './Faq';
import StyledFeedback from './Feedback';
import back from '../images/back.svg';
import logo from '../images/logo.svg';

const Main = ({ className }) => {
    return (
        <div className={className}>
            <StyledContacts />

            <div className="main">
                <div className="top">
                    <img src={logo} alt="Логотип" />
                    <h1>Психолог Влад Герасимов</h1>
                </div>
                <div className="leader">
                    <div>
                        <img src={photo} className="photo" alt="Logo" />
                    </div>
                    <div>
                        <p>Привет! Меня зовут Влад, я занимаюсь психологическими консультациями.</p>
                        <p>
                            Более семи лет работаю с молодёжью, помогая найти выход из затруднительных ситуаций. И раз
                            вы оказались на сайте психолога, то, вероятно, у вас есть ряд вопросов о консультациях и как
                            это применить к себе. Попробую на них ответить.
                        </p>
                    </div>
                </div>
                <StyledFaq />
                <StyledFeedback />
                <StyledAppointment />
                <StyledFooter />
            </div>
        </div>
    );
};

const StyledMain = styled(Main)`
    & {
        background-image: linear-gradient(to right, rgba(255, 254, 247, 0.9) 0 100%), url(${back});
        background-size: cover;
        background-attachment: fixed;

        padding: 3rem 4rem;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h1 {
        color: #9d6162;
        font-weight: bold;
        border-bottom: 1px solid rgba(173, 115, 27, 0.3);
    }

    .top {
        display: flex;
        gap: 0.5rem;
    }

    .top img {
        width: 3rem;
        height: 3rem;
    }

    .main {
        display: flex;
        flex-direction: column;
        max-width: 900px;
        background: white;
        border: 1px solid #d3d3d3;
    }

    .main > div:not(.appointment-container):not(.feedback):not(.leader) {
        margin: 2rem;
    }

    .leader {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        align-items: center;
        margin: 0 2rem 2rem 2rem;
    }

    .leader p {
        font-size: 1.4rem;
        line-height: 1.4;
    }

    .photo {
        width: 256px;
        border: 5px solid rgba(192, 172, 103, 0.5);
        border-radius: 128px;
    }

    .nohelp {
        color: rgb(128, 0, 0);
    }

    @media only screen and (max-width: 900px) {
        & {
            padding: 1rem;
        }

        .top img {
            width: 2.5rem;
            height: 2.5rem;
        }

        .main {
            max-width: 90vw;
        }

        .leader {
            flex-direction: column;
        }

        .main > div:not(.appointment-container):not(.feedback) {
            margin: 1rem;
        }
    }
`;

export default StyledMain;
