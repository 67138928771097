import { useEffect, useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import weekday from 'dayjs/plugin/weekday';
import arrowLeft from '../images/arrow-left.svg';
import arrowRight from '../images/arrow-right.svg';
import cross from '../images/cross.svg';

dayjs.locale('ru');
dayjs.extend(weekday);

const ChooseDate = ({ className, values, admin, setFieldValue }) => {
    const [show, setShow] = useState(false);
    const [dates, setDates] = useState(values.date);

    const [settings, setSettings] = useState({
        dates: [],
        settings: { startHour: 0, endHour: 23, workdays: Array(7).fill(0) },
    });

    const [week, setWeek] = useState(0);

    const [reccurent, setReccurent] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const setNextWeek = () => {
        if (week >= 4) return;
        setWeek(week + 1);
    };

    const setPreviousWeek = () => {
        if (week <= 0) return;
        setWeek(week - 1);
    };

    useEffect(() => {
        fetch(process.env.REACT_APP_API + '/api/appointments/occupied')
            .then(res => res.json())
            .then(res => setSettings(res))
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        let map = new Map();
        settings.dates
            .filter(d => d.recurring)
            .forEach(d => {
                const date = dayjs(d.date);
                const key = `${date.day() + 1}_${date.hour()}`;
                if (!map.has(key)) {
                    map.set(key, date);
                }
            });
        setReccurent(map);
    }, [settings.dates]);

    const monthName = (week, i) => {
        let str = dayjs()
            .add(week * 7 + i + 2, 'day')
            .format('MMM');
        str = str[0].toUpperCase() + str.slice(1);
        return str.replace('.', '');
    };

    function time(week, i, j) {
        const key = i + '_' + j;

        const date = dayjs()
            .add(week * 7 + i + 1, 'day')
            .hour(j)
            .minute(0)
            .second(0)
            .millisecond(0);

        if (settings.dates.some(d => d.date === date.toISOString())) {
            return (
                <li className={'disabled'} key={key}>
                    {date.format('HH:mm')}
                </li>
            );
        }

        if (!!reccurent) {
            const key = `${date.day() + 1}_${date.hour()}`;
            if (reccurent.has(key)) {
                return (
                    <li className={'disabled'} key={key}>
                        {date.format('HH:mm')}
                    </li>
                );
            }
        }

        if (settings.settings.workdays[date.weekday()] === 1) {
            return (
                <li
                    className={'enabled'}
                    key={key}
                    onClick={() => {
                        values.date.push(date.toDate());
                        setDates(values.date);
                        setFieldValue('date', values.date);
                        setShow(false);
                    }}>
                    {date.format('HH:mm')}
                </li>
            );
        } else {
            return (
                <li className={'disabled'} key={key}>
                    {date.format('HH:mm')}
                </li>
            );
        }
    }

    function range(size, startAt = 0) {
        return [...Array(size).keys()].map(i => i + startAt);
    }

    return (
        <div className={className}>
            {!admin && dates.length < 1 && (
                <Button variant="outline-success" onClick={handleShow}>
                    Выбрать удобное время для записи
                </Button>
            )}

            {dates.map((d, i) => (
                <div key={d}>
                    <span>{dayjs(d).format('DD MMMM (dd) в HH:mm')}</span>
                    <img
                        src={cross}
                        width={12}
                        alt={'Очистить дату'}
                        onClick={() => {
                            const newDates = dates.filter(date => d !== date);
                            setDates(newDates);
                            setFieldValue('date', newDates);
                        }}
                    />
                </div>
            ))}

            {admin && (
                <Button variant="outline-success" onClick={handleShow}>
                    Выбрать время
                </Button>
            )}

            <Offcanvas show={show} onHide={handleClose} className={className}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Выбрать время</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className={'week-picker'}>
                        <img
                            src={arrowLeft}
                            className={week <= 0 ? 'disabled' : 'enabled'}
                            width="20"
                            onClick={setPreviousWeek}
                            alt="Следующая неделя"
                        />
                        <img
                            src={arrowRight}
                            className={week >= 4 ? 'disabled' : 'enabled'}
                            width="20"
                            onClick={setNextWeek}
                            alt="Предыдущая неделя"
                        />
                    </div>
                    <div className={'picker'}>
                        {[...Array(7)].map((x, i) => (
                            <ul key={i}>
                                <li className={'month'}>
                                    {i === 0 ||
                                    dayjs()
                                        .add(week * 7 + i + 2, 'day')
                                        .month() !==
                                        dayjs()
                                            .add(week * 7 + i + 1, 'day')
                                            .month()
                                        ? monthName(week, i)
                                        : null}
                                </li>
                                <li className={'week'}>
                                    {dayjs()
                                        .add(week * 7 + i + 1, 'day')
                                        .format('D dd')}
                                </li>
                                {range(
                                    settings.settings.endHour - settings.settings.startHour + 1,
                                    settings.settings.startHour
                                ).map(j => time(week, i, j))}
                            </ul>
                        ))}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

const StyledChooseDate = styled(ChooseDate)`
    &:not(.offcanvas) {
        margin-top: 1rem;
        min-height: 38px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    & {
        z-index: 1056;
    }

    img {
        margin-left: 0.3rem;
        opacity: 0.5;
        cursor: pointer;
    }

    ul {
        list-style-type: none;
        margin: unset;
        padding: unset;
    }

    .week-picker {
        text-align: right;
        margin-bottom: 0.8rem;
    }

    .picker {
        display: flex;
        justify-content: center;
        gap: 1rem;
    }

    .month {
        min-height: 24px;
        font-weight: bold;
        margin-bottom: 0.4rem;
    }

    .week {
        text-transform: uppercase;
        font-size: 0.8rem;
    }

    .enabled {
        cursor: pointer;
        color: #0060a0;
    }

    .enabled:hover {
        color: rgb(192, 172, 103);
    }

    .disabled {
        opacity: 0.1;
    }

    button {
        max-width: 300px;
    }

    button:hover {
        color: #ffffff;
    }
`;

export default StyledChooseDate;
