import { Form, Button, Col, Row, Modal, Nav } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import StyledChooseDate from './ChooseDate';
import { useContext, useState } from 'react';
import { Context } from './Dashboard';
import dayjs from 'dayjs';
import styled from 'styled-components';
import editicon from '../images/edit.svg';

const AppointmentAdmin = ({ className, time, edit, appointment, menu }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const schema = yup.object().shape({
        name: yup.string().when('type', {
            is: 'visit',
            then: yup.string().required(),
        }),
        date: yup.array().of(yup.date().nullable()).min(1),
        recurring: yup.bool(),
        type: yup.string().required(),
        comment: yup.string(),
    });

    const callback = useContext(Context);

    function postAppointment(values) {
        console.log(values);
        var promises = [];

        values.date.forEach((d, i) => {
            const body = JSON.parse(JSON.stringify(values));
            body.date = values.date[i];

            promises.push(
                fetch(process.env.REACT_APP_API + '/api/appointments/add/custom', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                }).catch(err => console.log(err))
            );
        });
        Promise.all(promises)
            .then(callback)
            .then(handleClose);
    }

    return (
        <div className={className + (edit ? ' edit' : '') + (menu ? ' menu' : '')}>
            {!time && !edit && <Nav.Link onClick={handleShow}>Добавить</Nav.Link>}
            {time && !edit && (
                <span className={'time-enabled'} onClick={handleShow}>
                    {dayjs(time).format('HH:mm')}
                </span>
            )}
            {edit && <img src={editicon} onClick={handleShow} alt="Редактировать" style={{minWidth: '8px'}}/>}

            <Modal show={show} onHide={handleClose} className={'modal-admin'}>
                <Formik
                    validateOnMount
                    validationSchema={schema}
                    onSubmit={values => postAppointment(values)}
                    initialValues={{
                        name: edit ? appointment.name : '',
                        date: !!time ? [time] : [],
                        recurring: edit ? appointment.recurring : false,
                        type: edit ? appointment.type : 'visit',
                        comment: edit ? appointment.comment : '',
                    }}>
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                        dirty,
                        setFieldValue,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Header closeButton>{edit ? 'Редактировать' : 'Добавить'}</Modal.Header>
                            <Modal.Body>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md>
                                        <Form.Check
                                            type={'radio'}
                                            label={'Визит'}
                                            checked={values.type === 'visit'}
                                            onChange={e =>
                                                // fuck this
                                                setFieldValue('type', e.target.checked ? 'visit' : 'occupied')
                                            }
                                            disabled={edit}
                                        />
                                        <Form.Check
                                            type={'radio'}
                                            label={'Занят'}
                                            checked={values.type === 'occupied'}
                                            onChange={e =>
                                                setFieldValue('type', e.target.checked ? 'occupied' : 'visit')
                                            }
                                            disabled={edit}
                                        />
                                        <Form.Check
                                            type={'radio'}
                                            label={'Заявка с сайта'}
                                            defaultChecked={values.type === 'site'}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md>
                                        <Form.Label>Имя</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            disabled={values.type !== 'visit'}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <StyledChooseDate values={values} admin setFieldValue={setFieldValue} />
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md>
                                        <Form.Check
                                            name="recurring"
                                            label="Повторять каждую неделю"
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md>
                                        <Form.Label>Комментарий</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="comment"
                                            value={values.comment}
                                            onChange={handleChange}
                                            disabled={values.type !== 'visit'}
                                        />
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="success" type="submit" disabled={!isValid}>
                                    Добавить
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

const StyledAppointmentAdmin = styled(AppointmentAdmin)`
    .edit {
        min-width: 40px;
    }

    &:not(.edit):not(.menu) {
        width: fit-content;
        margin-left: auto;
    }

    .time-enabled {
        cursor: pointer;
        color: #0060a0;
    }

    .time:hover {
        color: rgb(192, 172, 103);
    }

    img {
        opacity: 0.5;
        cursor: pointer;
        vertical-align: unset; // fuck bootstrap
    }
`;

export default StyledAppointmentAdmin;
