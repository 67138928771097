import styled from 'styled-components';
import recurring from '../images/recurring.svg';
import cross from '../images/cross.svg';
import AppointmentAdmin from './AppointmentAdmin';
import Remove from './Remove';

const Date = ({ className, reccurent, available, key, appointment, callback }) => {
    const typeFancyCal = appointment => {
        switch (appointment.type) {
            case 'visit':
            case 'site':
                return appointment.name;
            case 'occupied':
                return 'Занят';
            default:
                return '';
        }
    };

    const removeAppointent = id => {
        const jwt = JSON.parse(sessionStorage.getItem('jwt'));

        fetch(process.env.REACT_APP_API + '/api/appointments/remove', {
            method: 'POST',
            headers: {
                'x-access-token': jwt.accessToken,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: id }),
        })
            //.then(_ => setReccurent(null))
            //.then(_ => fetchAppointments())
            .catch(err => console.log(err));
    };

    return (
        <>
            <div key={key} className={className + (available ? ' enabled' : '')}>
                {appointment ? (
                    <div className={'action ' + appointment.type}>
                        <span className={'name'}>{typeFancyCal(appointment)}</span>
                        <button onClick={() => removeAppointent(appointment.id)} />
                        {reccurent && <img src={recurring} className={'muted small'} width={8} alt="Еженедельный" />}
                        <AppointmentAdmin edit={true} appointment={appointment} />
                        <Remove
                            appointment={appointment}
                            callback={() => {
                                //setReccurent(null);
                                //fetchAppointments();
                            }}
                        />
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
        </>
    );
};

const StyledDate = styled(Date)`
    & {
        min-height: 25px;
    }

    .date {
        text-align: right;
        min-width: 40px;
        display: inline-block;
        padding: 2px 0;
    }

    .action {
        padding: 2px 4px;
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 2px;
        white-space: nowrap;
    }

    .action > span {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .name {
        margin-right: auto;
    }

    .action > button {
        padding: 0;
    }

    img {
        opacity: 0.5;
        cursor: pointer;
    }

    .small {
        opacity: 0.2;
        cursor: initial;
    }
`;

export default StyledDate;
