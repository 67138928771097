import { Form, Button, Col, Row, Modal, Nav } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useState, useEffect } from 'react';

const Settings = ({ className }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [settings, setSettings] = useState({ startHour: 0, endHour: 23, workdays: Array(7).fill(0) });

    const schema = yup.object().shape({
        startHour: yup.number().required().min(0).max(23),
        endHour: yup.number().required().min(0).max(23).moreThan(yup.ref('startHour')),
        workdays: yup.array().ensure().required(),
    });

    useEffect(() => {
        fetch(process.env.REACT_APP_API + '/api/settings/get')
            .then(res => res.json())
            .then(res => setSettings(res))
            .then(handleClose)
            .catch(err => console.log(err));
    }, []);

    function postSettings(values, resetForm) {
        fetch(process.env.REACT_APP_API + '/api/settings/set', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        })
            .then(resetForm)
            .catch(err => console.log(err));
    }

    return (
        <div className={className}>
            <Nav.Link onClick={handleShow}>Настройки</Nav.Link>

            <Modal show={show} onHide={handleClose} className={'modal-admin'}>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values, { resetForm }) => postSettings(values, resetForm)}
                    initialValues={{
                        startHour: settings.startHour,
                        endHour: settings.endHour,
                        workdays: settings.workdays,
                    }}>
                    {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, dirty }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>Настройки</Modal.Header>
                            <Modal.Body>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md>
                                        <Form.Label>Рабочее время, с</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="startHour"
                                            value={values.startHour}
                                            onChange={handleChange}
                                            isInvalid={!!errors.startHour}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md>
                                        <Form.Label>до</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="endHour"
                                            value={values.endHour}
                                            onChange={handleChange}
                                            isInvalid={!!errors.endHour}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Рабочая неделя</Form.Label>
                                        {[
                                            'Понедельник',
                                            'Вторник',
                                            'Среда',
                                            'Четверг',
                                            'Пятница',
                                            'Суббота',
                                            'Воскресенье',
                                        ].map((day, i) => {
                                            return (
                                                <Form.Check
                                                    key={day}
                                                    label={day}
                                                    defaultChecked={values.workdays[i] === 1}
                                                    onChange={e =>
                                                        e.currentTarget.checked
                                                            ? (values.workdays[i] = 1)
                                                            : (values.workdays[i] = 0)
                                                    }
                                                />
                                            );
                                        })}
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="success" type="submit" disabled={!isValid}>
                                    Сохранить
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

export default Settings;
