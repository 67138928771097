import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import styled from 'styled-components';
import cross from '../images/cross.svg';

const Remove = ({ className, appointment, callback }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const removeAppointent = id => {
        const jwt = JSON.parse(sessionStorage.getItem('jwt'));

        fetch(process.env.REACT_APP_API + '/api/appointments/remove', {
            method: 'POST',
            headers: {
                'x-access-token': jwt.accessToken,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: id }),
        })
            //.then(_ => setReccurent(null))
            //.then(_ => fetchAppointments())
            .then(callback)
            .catch(err => console.log(err));
    };

    return (
        <div className={className}>
            <button onClick={handleShow}>
                <img src={cross} width="12" alt="Удалить" />
            </button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton />
                <Modal.Body>
                    {appointment.recurring ? (
                        <p>Это повторная запись. Удалить только сегодня или всю целиком?</p>
                    ) : (
                        <p>Подтвердите удаление</p>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                    {appointment.recurring && <Button variant="secondary">Удалить сегодня</Button>}
                    <Button variant="primary" onClick={() => removeAppointent(appointment.id)}>
                        {appointment.recurring ? 'Удалить целиком' : 'Удалить'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Remove;
