import styled from 'styled-components';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

SwiperCore.use([Navigation, Pagination]);

const Feedback = ({ className }) => {
    return (
        <div className={className + ' feedback'}>
            <h2>Отзывы</h2>
            <div className="wrapper">
                <div className="prev"></div>
                <Swiper
                    slidesPerView={1}
                    navigation={{ prevEl: '.prev', nextEl: '.next' }}
                    pagination={{ clickable: true }}
                    spaceBetween={10}
                    breakpoints={{
                        600: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                    }}>
                    <div>
                        <SwiperSlide>
                            <div>
                                <h4>Катя П., 32 года</h4>
                                <p>
                                    Я работаю с Владом уже несколько месяцев и это первый раз за всю мою
                                    «психотерапевтическую» историю, когда у меня сложились такие долгие, доверительные,
                                    глубокие и невероятно продуктивные отношения с терапевтом. Я, правда, не могу в
                                    одном предложении срезюмировать, в чем секрет успеха Влада. Это и огромная база
                                    знаний разных направлений и методик, и невероятно проницательный, аналитический
                                    взгляд на вещи, и умение прочувствовать клиента со всеми «его особенностями» и найти
                                    наиболее оптимальный подход к работе. В результате, я могу полностью быть спокойна,
                                    что меня всегда поймут, поддержат и мягко проведут/направят, и подсветят важные
                                    моменты. Один факт наличия такого человека в жизни - уже носит невероятно
                                    терапевтический эффект!! А ещё у Влада невероятное чувство юмора, которое делает
                                    работу ещё более эффективной и общение ещё более доверительным. Так как мы часто
                                    склонны драматизировать там, где не надо:) После каждой сессии я чувствую прилив
                                    сил, воодушевление и радость от того, что снова смогла «встретиться» сама с собой и
                                    понять, что со мной все окей!
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <h4>Дмитрий, 25 лет</h4>
                                <p>
                                    Обратился к Владиславу в острый момент сомнений и неуверенности внутри.
                                    Прорабатывали с ним вопросы отношений, своего я в обществе и цели, которые предстоит
                                    осилить. За несколько сессий удалось снять внутренние противоречия и неуверенность.
                                    Порекомендую данного психолога! Спасибо!)
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <h4>Ульяна, 20 лет</h4>
                                <p>
                                    Влад — отличный специалист, очень чуткий, заинтересованный, эмпатичный человек.
                                    Встречи всегда проходят динамично и интенсивно, а главное, продуктивно — мы проводим
                                    сеансы уже практически год, и первые положительные изменения я начала замечать уже
                                    после третьей-четвёртой недели. Работа может проходить в любом удобном темпе и
                                    формате — Влад хорошо ознакомлен со всеми основными техниками и подходами в
                                    психологии. Ход сеанса идеально адаптируется под индивидуальные проблемы и
                                    контексты, что очень ценно и важно. Словом, пять звёзд :)
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <h4>Алим, 21 год</h4>
                                <p>
                                    Регулярно занимаюсь с Владом более года. С первых занятий стало понятно, что передо
                                    мной эрудированный профессионал, который легко находит со мной общий язык. Список
                                    затронутых и обработанных с ним тем зашкаливает. Мне легко признать, что я начал
                                    расти значительно эффективней именно благодаря Владу. У него прекрасно получается
                                    конструктивно анализировать и предлагать решения проблем. Рекомендую!
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <h4>Жумагуль, 26 лет</h4>
                                <p>
                                    Я обратилась к Владу в момент полнейшей растерянности, в момент, когда казалось, что
                                    земля уходит из под ног.
                                </p>
                                <p>
                                    У меня не было понимания кто я, чего хочу, что хорошо и правильно. За полтора года я
                                    сильно продвинулась во всех сферах своей жизни. Приходить раз в неделю и говорить о
                                    самых пугающих страхах, выносить на сессию самые болезненные ощущения &mdash; не
                                    просто, но окупается стократ.
                                </p>
                                <p>
                                    Помню как первые месяцы Влад аккуратно напоминал, что на сессиях мы должны говорить
                                    обо мне, а не о других людях, о моих чувствах, а не поведении близких &mdash; в
                                    общем, о моей отвественности, мы выработали у меня новую призму для анализа себя и
                                    мира. Очень благодарна, что попала в руки специалиста, который научил меня большей
                                    осознанности и саморегуляции.
                                </p>
                                <p>Я чувствую себя счастливее и целостнее, чем когда-либо.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <h4>Анастасия, 23 года</h4>
                                <p>
                                    К Владу я обратилась в стрессовый период жизни, когда происходящее вокруг казалось
                                    хаотичным, а проблемы из прошлого забирали последние ресурсы, казалось, что нет
                                    выхода и нет сил, чтобы что-то изменить. Я не могу сказать, что процесс терапии был
                                    легким в эмоциональном плане, но именно за счет этого эффективным. Были разобраны
                                    тревожащие кейсы, начиная с детства и заканчивая событиями, произошедшими буквально
                                    вчера. Понравилось приходить каждый раз то к логическому заключению, что это было
                                    тогда, а не сейчас, то к бунтарскому желанию быть счастливой вопреки всему. В
                                    течение нескольких недель ослабевала болезненная связь с прошлым, благодаря чему в
                                    процессе терапии и после неё происходило принятие всего произошедшего, росла
                                    уверенность, что текущие решения значат куда больше, чем события прошедших дней.
                                </p>
                                <p>
                                    К концу терапии удалось достичь убежденности в праве жить своей жизнью, ощущения,
                                    что всегда можно опереться на себя, влиять на происходящее вокруг. За счет доверия к
                                    себе появилось доверие к миру и свобода самовыражения. Тревога, связанная с будущим,
                                    теперь проявляет себя в пределах допустимого, нет страха потерять контроль. И, что
                                    было для меня важно, наладились отношения с семьей. Если кратко, то всё, что раньше
                                    имело смысл, но страх потери этого лишал меня сил, теперь, наоборот, вдохновляет и
                                    поддерживает. Появилось ощущение, что я - это самый главный гарант личной
                                    безопасности, а не основная опасность. Теперь выбор между спрятаться и показать себя
                                    исходит из желания, а не из страха. Навык проживания эмоций принёс мне больше
                                    спокойствия, чем попытки подавления этих эмоций. Вместо стопорившего любые действия
                                    убеждения "надо сделать всё идеально" в процессе терапии я научилась делать то, что
                                    могу.
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <h4>Александр, 26 лет</h4>
                                <p>
                                    Обратился к Владу в 21 год &mdash; вышел на него благодаря счастливому рандому,
                                    находился тогда в состоянии молча кричащего комка боли, неспособного найти себя и
                                    свое место в реальности.
                                </p>
                                <p>
                                    Влад интеллектуален и любознателен, весьма эрудирован и обладает ярким бэком,
                                    говорит на одном со мной языке &mdash; честном и открытом. Я не смог общаться с
                                    психологами, которые говорили на чужом для меня языке, были слишком мягкими (до
                                    соплей) или слишком активными (до суеты) или слишком высокомерными («догадайся, что
                                    я имею в виду»). Язык и мышление, которое он отражает, и терпение пополам с
                                    принятием оказались ключевыми пойнтами для доверия &mdash; без этого мостика я бы не
                                    смог в критической ситуации за месяц с небольшим интенсивных сеансов выйти из того,
                                    что было для меня адом, длившимся 14 лет.
                                </p>
                                <p>
                                    Второй раз обратился к Владу в 24 года, проработали полтора года вместе. Это была
                                    терапия «медленного сгорания», по итогам которой у меня выровнялось скачущее
                                    самоощущение и получилось «развязать» несколько привычек, по сути &mdash; долгая и
                                    кропотливая работа. Не самая приятная местами. Поддерживало желание продолжать
                                    работу то, что можно было сказать о дискомфорте и несогласии прямо в тот же момент,
                                    когда они возникали, и каждый раз в диалоге находились решения. Ни разу не встречал
                                    высокомерия или двойной игры в чсв, только честность и открытость.
                                </p>
                                <p>
                                    Язык общения в этот период отвечал запросу на создание инструмента по отслеживанию
                                    скрытой от восприятия внутренней динамики (если вдруг для кого-то это что-то
                                    значит).
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <h4>Арина, 20 лет</h4>
                                <p>
                                    Я решила обратиться к Владу, потому что долгое время меня мучило чувство
                                    неуверенности в себе и во всем, что я делаю. После самой первой консультации я
                                    почувствовала очень сильное облегчение, которое продолжаю испытывать после каждой
                                    нашей беседы. Каждый раз я осознаю что-то очень важное, это помогает мне все лучше и
                                    лучше понимать себя и свои эмоции, защищать свои личные границы и избавляться от
                                    факторов стресса в своей жизни. Советы, которые даёт мне Влад во время консультаций,
                                    действительно помогают мне справляться с обыденными ситуациями, которые раньше были
                                    источником моего плохого настроения и тревоги. Мне кажется, Владу очень хорошо
                                    удается улавливать суть проблемы. Вопросы, которые он мне задает, часто приводят
                                    меня к «открытиям», а значит являются действительно правильными. Он объясняет многие
                                    вещи на очень крутых примерах и аналогиях, поэтому консультации проходят очень
                                    интересно.
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <h4>Максим, 21 год</h4>
                                <p>
                                    Мне сложно было осознать в процессе наших занятий, как именно произойдут изменения
                                    во мне. Я занимался с Владом на протяжении почти года и, как оказалось, это
                                    произвело большой эффект. Мне кажется, только спустя продолжительный промежуток
                                    времени становится и правда очевидно, что многое поменялось: я осознанно вышел из
                                    сковывающих меня отношений (хотя абсолютно не признавал этого для себя раньше),
                                    открыл для себя занятия приносящие мне огромное удовольствие, сильно пересмотрел
                                    свой распорядок дня, наполнив его положительно эмоциональными маленькими
                                    путешествиями (которых так хотел и раньше, но почему-то избегал, разменивая на более
                                    пластиковые вещи) и пересмотрел свой взгляд на счет работы. Я считаю, что сделал
                                    действительно большой шаг на пути понимания и признания себя благодаря нашим
                                    занятиям с Владом.
                                </p>
                            </div>
                        </SwiperSlide>
                    </div>
                </Swiper>
                <div className="next"></div>
            </div>
        </div>
    );
};

const StyledFeedback = styled(Feedback)`
    & {
        --swiper-theme-color: rgb(0, 60, 110);
    }

    .prev,
    .next {
        width: 36px;
    }

    p {
        text-align: justify;
    }

    h2 {
        margin-left: 2rem;
    }

    .swiper-container {
        height: 410px;
        margin-top: 1rem;
    }

    .swiper-slide {
        text-align: left;
        margin: 0;
    }

    .swiper-slide > div {
        padding: 2.5rem;
        overflow-y: auto;
        max-height: 90%;
        height: 90%;

        // https://lea.verou.me/2012/04/background-attachment-local/
        background:
            /* Shadow covers */ linear-gradient(#f1f1f1 30%, rgba(255, 255, 255, 0)),
            linear-gradient(rgba(255, 255, 255, 0), #f1f1f1 70%) 0 100%,
            /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
            radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;

        background:
            /* Shadow covers */ linear-gradient(#f1f1f1 30%, rgba(255, 255, 255, 0)),
            linear-gradient(rgba(255, 255, 255, 0), #f1f1f1 70%) 0 100%,
            /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
            radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;

        background-repeat: no-repeat;
        background-color: #f1f1f1;
        background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

        /* Opera doesn't support this in the shorthand */
        background-attachment: local, local, scroll, scroll;
    }

    .wrapper {
        display: flex;
        align-items: center;
    }

    .prev,
    .next {
        font-family: 'swiper-icons';
        cursor: pointer;
        font-size: 1.5rem;
        padding: 0.75rem;
        color: rgb(0, 96, 160);
    }

    .prev:before {
        content: 'prev';
    }

    .next:after {
        content: 'next';
    }

    .swiper-button-disabled {
        opacity: 0.5;
    }

    @media only screen and (max-width: 900px) {
        .swiper-slide > div {
            padding: 1.5rem;
        }
    }
`;

export default StyledFeedback;
