import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';
import expand from '../images/expand.svg';

const Faq = ({ className }) => {
    return (
        <div className={className}>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>С чем я могу помочь?</Accordion.Header>
                    <Accordion.Body>
                        К темам, с которыми я работаю чаще всего, можно отнести кризис четверти жизни, проблемы с
                        мотивацией, прокрастинацией, поиск собственной идентичности, выбор карьерных треков, сложности с
                        выстраиванием отношений с родственниками, окружением, партнёрами.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        Как понять, что мне или кому-то из близких пригодилась бы консультация?
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Обычно люди обращаются к психологам, когда возникает ощущение, что собственные попытки найти
                            выход из сложной ситуации не приносят желаемых результатов. Иногда даже бывает, что мы
                            чувствуем, что знаем нужное решение, или понимаем, что друзья дают хороший совет, но по
                            какой-то непонятной причине не можем заставить себя сделать всё «правильно».
                        </p>
                        <p>
                            Психолог может помочь разобраться, что же внутри нашей психики вызывает подобные
                            сопротивления; что влияет на что, и как это всё меж собой примирить, чтобы можно было со
                            спокойной душой жить полноценной жизнью без лишних внутренних терзаний.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>А если у меня нет чёткого запроса, я не знаю в чём проблема?</Accordion.Header>
                    <Accordion.Body>
                        Если у вас нет чёткого понимания, в чём проблема, но есть ощущение, что что-то в жизни идёт «не
                        так», то нам будет, о чём побеседовать, если у вас проскальзывают мысли вроде таких:
                        <ul>
                            <li>Не понимаю чего хочу</li>
                            <li>Ничего не делаю</li>
                            <li>Раздражают люди, всё бесит</li>
                            <li>Чувствую одиночество</li>
                            <li>Как доверять людям?</li>
                            <li>Испытываю чувство вины</li>
                            <li>Не знаю, как пережить расставание</li>
                            <li>Как найти в себе силы?</li>
                            <li>Как сделать выбор?</li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>В чём специфика вашего подхода? </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Я работаю по принципам <b>клиент-центрированной терапии</b>, то есть исхожу из того, что
                            именно клиент является автором своей жизни, и его благо превыше всего. Никакая общая теория
                            или метод не могут претендовать на то, чтобы рассказать конкретному клиенту с его
                            неповторимой индивидуальностью, как именно ему лучше жить свою жизнь. Я работаю с тем, что в
                            человеке и так уже есть по факту. Поэтому для меня не являются ограничивающими факторами
                            вероисповедание, пол, сексуальная ориентация, политические предпочтения, национальности и
                            прочие подобные аспекты. Вместе в ходе консультаций мы выявляем существующие запросы, цели,
                            затруднения, и находим пути, как клиент в условиях своего актуального контекста может
                            улучшить положение дел.
                        </p>
                        <p>
                            С практической стороны я придерживаюсь <b>интегративного подхода</b>, то есть использую
                            механики и техники разных терапевтических школ и парадигм (гештальт-терапия, транзактный
                            анализ, когнитивно-поведенческая терапия, экзистенциальная терапия, юнгианский анализ).
                            Широта используемых методов позволяет подбирать оптимальный формат взаимодействия с каждым
                            клиентом для разных терапевтических ситуаций. В конце концов, главное, чтобы клиенту было
                            комфортно, удобно и безопасно в рамках консультаций, иначе, зачем это всё?
                        </p>
                        <p className="quote">«Все методы на благо клиента, а не все клиенты под один метод»</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Сколько сеансов потребуется для решения проблемы?</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Заранее сказать практически невозможно, так как каждый случай это уникальный клиент в своём
                            уникальном контексте с уникальным сплетением запросов. Некоторые вопросы решаются за 1-2
                            сеанса, с некоторыми глобальными темами иногда можно работать по году и более. Усреднённо
                            могу сказать, что качественные позитивные сдвиги в целом обычно становятся заметны через 2-3
                            месяца.
                        </p>
                        <p>
                            Некоторые клиенты любят работать без привязки к «решению проблем». Консультации вполне можно
                            проводить для общего тонуса и развития желаемых навыков, не дожидаясь, когда проблемы
                            возникнут. Для многих терапевтический процесс становится интересен сам по себе!
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>
                        <span className="nohelp">С чем я не смогу помочь</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Я не имею права ставить вам диагноз или выписывать какие-либо медикаменты (этим занимаются
                            врачи-психиатры).
                        </p>
                        <p>
                            Я не гуру и не волшебник, так что я не могу гарантировать вам универсальное стопроцентное
                            разрешение всех ваших проблем к понедельнику такого-то числа.
                        </p>
                        <p>
                            Я не работаю с людьми с наркотической и другими зависимостями, людьми с тяжёлыми
                            психиатрическими диагнозами. С ними закономерно должны работать врачи &mdash; психиатры, неврологи
                            и наркологи.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>Какой формат консультаций и их стоимость?</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            Консультация проводится онлайн (в Skype или Telegram), длится 1 час, периодичность &mdash; раз в
                            неделю. Это «золотой стандарт», однако, при необходимости можно договориться об иных
                            форматах.
                        </p>
                        <p>Стоимость &mdash; 3500 рублей</p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};

const StyledFaq = styled(Faq)`
    & {
        margin-top: 2rem;
    }

    .quote {
        text-align: center;
        font-style: italic;
        color: rgb(0, 60, 110);
        font-size: 1.5rem;
    }

    ul {
        list-style: none;
        padding-left: 1rem;
    }

    ul li:before {
        content: '—';
        position: relative;
        left: -5px;
    }

    .accordion-button::after {
        position: absolute;
        background-image: url(${expand});
        margin-top: -6px;
        margin-right: -3px;
        top: 0;
        right: 0;
    }

    .accordion-button:not(.collapsed)::after {
        background: none;
    }
`;

export default StyledFaq;
