import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useHistory, useLocation } from 'react-router';
import lock from '../images/lock.svg';
import lock_open from '../images/lock-open.svg';

const Login = ({ className }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (isLoggedIn) {
            history.push('/admin');
        }
    }, [isLoggedIn, history]);

    const schema = yup.object().shape({
        password: yup.string().required(),
    });

    function goToAdmin() {
        history.push('/admin');
    }

    function login(password) {
        fetch(process.env.REACT_APP_API + '/api/auth/signin', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(password),
        })
            .then(response => response.json())
            .then(response => sessionStorage.setItem('jwt', JSON.stringify(response.accessToken)))
            .then(response => setIsLoggedIn(true))
            .catch(err => console.log(err));
    }

    function loginButton() {
        if (location.pathname.startsWith('/admin')) {
            return null;
        }

        const isLoggedIn = !!JSON.parse(sessionStorage.getItem('jwt'));

        if (isLoggedIn) {
            return <img src={lock_open} width={12} onClick={goToAdmin} alt={'Войти'} />;
        } else {
            return <img src={lock} width={12} onClick={handleShow} alt={'Войти'} />;
        }
    }

    return (
        <div className={className}>
            {loginButton()}

            <Modal show={show} onHide={handleClose} animation={false}>
                <Formik
                    validationSchema={schema}
                    onSubmit={values => login(values)}
                    initialValues={{
                        password: '',
                    }}>
                    {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Form.Group>
                                    <Form.Label>Пароль</Form.Label>
                                    <Form.Control
                                        required
                                        type="password"
                                        name="password"
                                        autoFocus
                                        value={values.password}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="submit" disabled={!!!values.password}>
                                    Войти
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

const StyledLogin = styled(Login)`
    img {
        cursor: pointer;
        opacity: 0.1;
    }

    img:hover {
        opacity: 0.7;
    }
`;

export default StyledLogin;
