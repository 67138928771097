import { Form, Button } from 'react-bootstrap';
import styled from 'styled-components';
import * as yup from 'yup';
import { Formik } from 'formik';
import StyledChooseDate from './ChooseDate';
import { useState } from 'react';
import check from '../images/check.svg';

const Appointment = ({ className }) => {
    const [done, setDone] = useState(false);

    const schema = yup.object().shape({
        name: yup.string().required(),
        contact: yup.string().required(),
        message: yup.string(),
        date: yup.array().of(yup.date().nullable()),
    });

    function postAppointment(values) {
        if (values.date.length === 0) {
            values.date = null;
        } else {
            values.date = values.date[0];
        }
        fetch(process.env.REACT_APP_API + '/api/appointments/add', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        })
            .then(response => response.json())
            .then(_ => setDone(true))
            .catch(err => console.log(err));
    }

    return (
        <div className={className + ' appointment-container'}>
            <div className="appointment">
                <h2>Записаться на консультацию</h2>
                <p>
                    <i>или связаться по любому вопросу</i>
                </p>
            </div>
            <Formik
                validationSchema={schema}
                onSubmit={values => postAppointment(values)}
                initialValues={{
                    name: '',
                    contact: '',
                    message: '',
                    date: [],
                }}>
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                    dirty,
                    setFieldValue,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <div className="grid-container">
                            <div className="label-container">
                                <Form.Label>
                                    Как к вам обращаться<span className="red">*</span>
                                </Form.Label>
                            </div>
                            <div>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    disabled={done}
                                />
                            </div>
                            <div className="label-container">
                                <Form.Label>
                                    Как с вами связаться<span className="red">*</span>
                                </Form.Label>
                            </div>
                            <div>
                                <Form.Control
                                    type="text"
                                    name="contact"
                                    placeholder="Почта, телефон, Telegram, Skype"
                                    value={values.contact}
                                    onChange={handleChange}
                                    disabled={done}
                                />
                            </div>
                            <div className="label-container">
                                <Form.Label>Любая дополнительная информация</Form.Label>
                            </div>
                            <div>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="message"
                                    value={values.message}
                                    onChange={handleChange}
                                    disabled={done}
                                />
                                <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                                <div className="choose">
                                    <StyledChooseDate values={values} setFieldValue={setFieldValue} />
                                    <p>Стоимость &mdash; 3500&nbsp;₽</p>
                                </div>
                            </div>
                        </div>
                        <div className="appointment-bottom">
                            <div className="sign-button">
                                <Button
                                    variant="success"
                                    type="submit"
                                    disabled={(!isValid && dirty) || !dirty || done}>
                                    {done ? (
                                        <>
                                            <img src={check} height={18} alt="" />
                                            Вы записаны
                                        </>
                                    ) : (
                                        'Записаться'
                                    )}
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

const StyledAppointment = styled(Appointment)`
    & {
        margin-top: 2rem;
        background: #fffaf0;
    }
    .grid-container {
        display: grid;
        grid-template-columns: 200px auto;
        grid-template-rows: repeat(3, auto);
        grid-gap: 1rem;
    }

    .appointment {
        padding: 2rem 2rem 0 2rem;
        border-top: 1px dotted #faa634;
    }

    .appointment h2 {
        margin: 0;
    }

    .red {
        color: red;
        margin-left: 0.1rem;
    }

    .grid-container {
        padding: 0 2rem 2rem 2rem;
    }

    .appointment-bottom {
        background: #f7efe0;
        padding: 1.5rem;
        padding-left: calc(200px + 3rem);
        border-bottom: 1px dotted #faa634;
    }

    .label-container {
        margin-top: 0.5rem;
    }

    input,
    textarea {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
    }

    .choose {
        display: flex;
        gap: 1rem;
        align-items: center;
    }

    .choose p {
        margin: 1rem 0 0 0;
    }

    .sign-button > .form-label {
        margin: unset;
    }

    .sign-button > button {
        width: 200px;
        font-weight: 900;
        font-size: 1.2rem;
        color: #ffffff;
    }

    img {
        opacity: 0.5;
        margin-right: 0.5rem;
    }

    .form-control:disabled {
        background-color: #e9e5d8;
    }

    @media only screen and (max-width: 900px) {
        .grid-container {
            grid-template-columns: auto;
            grid-gap: initial;
        }

        .appointment-bottom {
            padding: 2rem;
        }

        .appointment {
            padding: 1rem 1rem 0 1rem;
        }

        .grid-container {
            padding: 0 1rem 1rem 1rem;
        }

        .appointment-bottom {
            padding: 1rem;
        }
    }
`;

export default StyledAppointment;
