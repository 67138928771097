import styled from 'styled-components';
import StyledLogin from './Login';

const Footer = ({ className }) => {
    return (
        <div className={className}>
            <p>
                &copy;&nbsp;2019...{new Date().getFullYear()}&nbsp;&nbsp;·&nbsp;&nbsp;
                <a href="mailto:mail@vladpsy.ru">mail@vladpsy.ru</a>
            </p>
            <StyledLogin />
        </div>
    );
};

const StyledFooter = styled(Footer)`
    & {
        margin-top: 3rem;
        display: flex;
        justify-content: space-between;
    }

    p {
        margin-top: 1rem;
        color: #b3b3b3;
    }

    div {
        align-self: center;
    }
`;

export default StyledFooter;
