import { Container, Nav, Navbar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import AppointmentAdmin from './AppointmentAdmin';
import StyledSettings from './Settings';

const Menu = ({ className }) => {
    const history = useHistory();

    function handleLogout() {
        sessionStorage.removeItem('jwt');
        history.push('/');
    }

    return (
        <div className={className}>
            <Navbar expand="lg" bg="dark" variant="dark">
                <Container>
                    <Navbar.Toggle />
                    <Navbar.Collapse>
                        <Nav className="me-auto">
                            <AppointmentAdmin menu/>
                            <StyledSettings />
                        </Nav>
                        <Nav>
                            <Nav.Link onClick={handleLogout}>Выйти</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

export default Menu;
