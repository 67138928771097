import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [isAuthTrue, setIsAuthTrue] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setIsAuthTrue(!!JSON.parse(sessionStorage.getItem('jwt')));
        setLoading(false);
    }, [setIsAuthTrue]);

    return (
        <>
            {loading ? (
                <div>Authentication...</div>
            ) : (
                <Route
                    {...rest}
                    render={props => (!!isAuthTrue ? <Component {...props} /> : <Redirect to={{ pathname: '/' }} />)}
                />
            )}
        </>
    );
};
export default PrivateRoute;
