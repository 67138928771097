import styled from 'styled-components';
import skype from '../images/skype.svg';
import telegram from '../images/telegram.svg';
import vk from '../images/vk.svg';

const Contacts = ({ className }) => {
    return (
        <div className={className}>
            <div className="contacts">
                <a href="//t.me/vlaydan"><img src={telegram} width="28px" alt="Telegram"/></a>
                <span>·</span>
                <a href="skype:vlaydan"><img src={skype} width="28px" alt="Skype"/></a>
                <span>·</span>
                <a href="//vk.com/vlaydan"><img src={vk} width="34px" alt="VK"/></a>
            </div>
            <span className="mail"><a href="mailto:mail@vladpsy.ru">mail@vladpsy.ru</a></span>
            <span className="desc">Связаться по любому вопросу или записаться<br/>на консультацию</span>
        </div>
    );
};

const StyledContacts = styled(Contacts)`
    & {
        position: fixed;
        right: 4rem;
        top: 3rem;
        max-width: 180px;
        display: flex;
        flex-direction: column;
        gap: .3rem;
    }

    span {
        display: block;
        text-align: center;
    }

    .mail {
        font-size: 1.1rem;
    }

    .desc {
        font-size: .9rem;
        line-height: 1rem;
        width: 180px;
    }

    .contacts {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        margin: .3rem;
    }

    img {
        opacity: .6;
        transition: transform 250ms, opacity 400ms ease;
    }

    img:hover {
        opacity: 1;
        transform: scale(1.1);
    }

    @media only screen and (max-width: 1370px){
        & {
            position: initial;
            margin: 1rem;
        }

    }
`;

export default StyledContacts;
